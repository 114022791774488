import genericError from '../Assets/Img/GenericError.svg'
// @ts-ignore
import { Button } from '@tdt-global/styleguide'
import { useLocation, useNavigate } from 'react-router-dom'

export const ServiceError = () => {
    let navigate = useNavigate()
    let location = useLocation()
    let suiteApp = '/suite-apps'
    return (
        <section id='service-error'>
            <div className='image'>
                <img src={genericError} alt='Error' />
            </div>
            <div className='number'>Ups</div>
            <div className='text'>Algo salio mal.</div>
            {location.pathname !== suiteApp && (
                <Button onClick={() => navigate(suiteApp)} type={'primary'}>
                    Volver
                </Button>
            )}
        </section>
    )
}
